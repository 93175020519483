@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .list-style {
    @apply cursor-pointer hover:text-Orange transition-shadow duration-500;
  }

  .styleButton {
    @apply bg-primary text-white font-medium border hover:text-primary hover:border-primary rounded hover:bg-white transition duration-500;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.background {
  background: linear-gradient(
    90deg,
    rgba(254, 132, 2, 1) 0%,
    rgba(254, 132, 2, 1) 25%,
    rgba(238, 12, 130, 1) 100%
  );
}

@layer utilities {
  /* Custom CSS */
  .text-gradient {
    /* Set the text color to transparent */
    color: transparent;
    /* Apply a linear gradient to the text */
    background-image: linear-gradient(
      to right,
      rgba(254, 132, 2, 1),
      rgba(238, 12, 130, 1)
    );
    /* Display the background behind the text */
    -webkit-background-clip: text;
    background-clip: text;
  }

  .whatwedo {
    /* background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%); */
    background-image: linear-gradient(
      to top,
      #dad4ec 0%,
      #dad4ec 1%,
      #f3e7e9 100%
    );
  }

  /* Pattern */
  .pattern:before {
    content: "";
    background: url("./Images/Home/cluster-pattern.png");
    width: 44px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: -0.5rem;
    top: -1rem;
  }
  .scale-up-br {
    -webkit-animation: scale-up-br 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-br 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
}

@-webkit-keyframes scale-up-br {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}
@keyframes scale-up-br {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}
